import React, { useEffect, useState, useRef } from "react";
import "../../styles/auth.scss";
import { useParams } from 'react-router-dom';
import { Card, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import { FileDrop } from 'react-file-drop';
import { CloudUploadFill } from 'react-bootstrap-icons';
import Loader from 'react-loader-spinner';

interface File {
  name:string;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

interface routeParams{
  id:string;
}

function FilesNew() {

  const [data, setData] = useState<Partial<File>>({});
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles]:any = useState([]);
  const { id } = useParams<routeParams>();
  const [loading, showLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [message, setMessage] = useState({
    title:'',
    body:''
  })

  const handleClose = () => {
    setError(false);
  }

  const onUpload = (files:any, event:any) => {
    console.log('onDrop!', files, event);
    let fileList = selectedFiles;
    for (var i = files.length - 1; i >= 0; i--) {      
      fileList.push(files[i]);
    }    
    setSelectedFiles([...fileList]);
  };

  const onFileInputChange = (event:any) => {
    console.log(event.target.files);
    let fileList = selectedFiles;
    for (var i = event.target.files.length - 1; i >= 0; i--) {      
      fileList.push(event.target.files[i]);
    }    
    setSelectedFiles([...fileList]);
  }

  const onTargetClick = (event:React.MouseEvent) => {
    event.preventDefault();
    if(fileInputRef.current){
      fileInputRef.current.click()
    }
  }

  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>)=>{
    let file = data;
    file[e.target.name] = e.target.value;
    setData(file);
  }

  const onSubmitHandler = (e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    showLoading(true);
    let formdata = new FormData();
    for (var x = 0; x < selectedFiles.length ; x++) {
      formdata.append("file[]", selectedFiles[x]);
    }
    formdata.append("repository", id);
    for (const field in data) {
      formdata.append(field, data[field]);
    }
    request('/api/file',{
      method:'POST',
      body: formdata,
      file: true
    })
    .then((results:Result)=>{
      console.log(results);
      if(results.error){
        if(results.error.code){
          setMessage({
            title:'Error',
            body: 'No se seleccionó un archivo para subir.'
          });
        }else{          
          setMessage({
            title:'Error',
            body: 'Ocurrió un error para subir el archivo.'
          });
        }
        setError(true);
      }else{
        window.location.href = "/repository/files/"+id;
      }
      showLoading(false);
    })
  }

  return (
    <div className="files-form mt-5 pt-5 pb-5">
      {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
      <Row>
        <Col xs={12} sm={12} md={{span:8,offset:2}} lg={{span:6,offset:3}}>          
          <Card className="w-100">
            <Form onSubmit={onSubmitHandler}>          
              <Card.Body>
                <Card.Title>Nuevos archivos</Card.Title>
                <Row className="justify-content-center">
                  <Col xs={12} md={6}>
                    <input
                      onChange={onFileInputChange}
                      ref={fileInputRef}
                      type="file"
                      multiple
                      className="hidden"
                    />
                    <FileDrop
                      onDrop={onUpload}
                      className="bg-gray"
                    >
                      <p className="text-center">Suelta aquí tus archivos<br/> o <a href="#" className="subtitle-font" onClick={onTargetClick}>haz clic aquí</a> para seleccionarlos desde tu PC.</p>                
                      <CloudUploadFill size="150"/>
                    </FileDrop>
                  </Col>
                  <Col xs={{span:8,offset:2}}>
                    <p className="mt-3 text-left"><b>Archivos a subir:</b></p>
                     <ul className="text-left">
                       {
                         selectedFiles.map( (item:any,index:number)=>(
                           <li>{item.name}</li>
                          ))
                       }
                     </ul> 
                  </Col>
                </Row>
                <Button variant="primary" type="submit" disabled={ selectedFiles.length < 1 }>
                  Subir archivos
                </Button>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ message.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilesNew;
