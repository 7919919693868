import React, { useEffect, useState } from "react";
import "../styles/header.scss";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { request } from "../services/Request";

function Header() {
  const [isLoggedIn, setLogIn] = useState(false);
  const [userData, setData]: any = useState({
    role: 2,
    permissions:{}
  });

  useEffect(() => {
    request("/api/auth/me", { noRedirect: true })
      .then((result: any) => {
        if (result.data) {
          setLogIn(true);
          setData(result.data);
        }
      })
      .catch((error) => {});
  }, []);

  const logout = (e: any) => {
    e.preventDefault();
    delete localStorage.user;
    delete localStorage.token;
    window.location.href = "/";
  };

  return (
    <header className="header fixed-top">
      <Navbar collapseOnSelect expand="md" bg="light" variant="light">
        <Navbar.Brand>
          <a href="/" className="text-dark">
            CloudBox
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {!isLoggedIn && <Nav.Link href="/login">Inicio sesión</Nav.Link>}
            {isLoggedIn && <Nav.Link href="/home">Repositorios</Nav.Link>}
            {isLoggedIn && userData.role == 1 && <Nav.Link href="/users">Usuarios</Nav.Link>}
            {isLoggedIn && (
              <NavDropdown
                title={userData.name + " " + userData.lastname}
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={logout}>
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
