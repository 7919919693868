import React, { useEffect, useState } from "react";
import "../../styles/repository.scss";
import { Button, Row, Col, Table, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import { Trash, List } from 'react-bootstrap-icons';
import Loader from 'react-loader-spinner';

interface Repository {
  name:string;
  _id:string;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

function RepositoryList() {

  const [data, setData] = useState<Partial<Repository>>([]);
  const [showRemove, setRemoveModal] = useState(false);
  const [selectedRepository, setSelected] = useState<Partial<Repository>>({});
  const [loading, showLoading] = useState(false);

  useEffect(()=>{
    getRepositories();
  },[]);

  const getRepositories = () => {
    showLoading(true);
    request('/api/repository',{})
    .then((result:Result)=>{
      if(result.error){

      }else{
        setData(result.data);
      }
      showLoading(false);
    });
  }

  const remove = (item:Repository) => {
    setRemoveModal(true);
    setSelected(item);
  }

  const handleClose = () => {
    setRemoveModal(false);
  }

  const confirmRemove = () => {
    showLoading(true);
    request('/api/repository/'+selectedRepository._id,{
      method: 'DELETE'
    })
    .then((result:Result)=>{
      if(result.error){

      }else{
        setRemoveModal(false);
        window.location.reload();
      }
      showLoading(false);
    });
  }

  return (
    <div className="repository-list mt-5 pt-5 pb-5">
    {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
    <Row className="">
        <Col xs={12} md={{span:3,offset:2}}>
          <h2 className="text-left">Repositorios</h2>
        </Col>
        <Col xs={12} md={{span:2,offset:3}}>
          <a href={"/repository/new"} className="btn btn-primary w-100">
            Nuevo
          </a>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={{span:8, offset:2}}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Repositorio</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((repository:Repository,index:number)=>(
                  <tr>
                    <td>
                      <a href={"/repository/files/"+repository._id}>                        
                        { repository.name }
                      </a>
                    </td>
                    <td>
                      <a href={"/repository/files/"+repository._id} className="btn btn-secondary">
                        <List/>
                      </a>
                      <Button variant="danger" onClick={() => remove(repository) }>
                        <Trash/>
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showRemove} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar repositorio</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar el repositorio {selectedRepository.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RepositoryList;
