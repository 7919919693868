import React, { useEffect, useState } from "react";
import "../../styles/auth.scss";
import { Card, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import Loader from 'react-loader-spinner';

interface User {
  name:string;
  lastname:string;
  email:string;
  password:string;
  status:boolean;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

function UserNew() {

  const [data, setData] = useState<Partial<User>>({});
  const [loading, showLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [message, setMessage] = useState({
    title:'',
    body:''
  })

  const handleClose = () => {
    setError(false);
  }

  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>)=>{
    let user = data;
    user[e.target.name] = e.target.value;
    setData(user);
  }

  const onSubmitHandler = (e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    showLoading(true);
    request('/api/user',{
      method:'POST',
      body: JSON.stringify( data )
    })
    .then((results:Result)=>{
      if(results.error){
        setMessage({
          title:'Error',
          body: 'Ocurrió un error al crear el usuario.'
        });        
        setError(true);
      }else{
        window.location.href = "/users";
      }
      showLoading(false);
    })
  }

  return (
    <div className="user-form mt-5 pt-5 pb-5">
      {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
      <Row>
        <Col xs={12} sm={12} md={{span:8,offset:2}} lg={{span:6,offset:3}}>          
          <Card className="w-100">
            <Form onSubmit={onSubmitHandler}>          
              <Card.Body>
                <Card.Title>Nuevo usuario</Card.Title>
                <Form.Group controlId="name">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control type="text" required placeholder="Ingrese un nombre" name="name" onChange={onChangeHandler} />
                </Form.Group>
                <Form.Group controlId="lastname">
                  <Form.Label>Apellido(s)</Form.Label>
                  <Form.Control type="text" required placeholder="Ingrese los apellidos" name="lastname" onChange={onChangeHandler} />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Correo electrónico</Form.Label>
                  <Form.Control type="email" required placeholder="Ingrese un correo" name="email" onChange={onChangeHandler} />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Crear
                </Button>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ message.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserNew;
