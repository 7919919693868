import React, { useEffect, useState } from "react";
import "../../styles/auth.scss";
import { Card, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import Loader from 'react-loader-spinner';

interface User {
  email:string;
  password:string;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

function Login() {

  const [data, setData] = useState<Partial<User>>({});
  const [loading, showLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [showPasswordModal, setPasswordModal] = useState(false);
  const [message, setMessage] = useState({
    title:'',
    body:''
  });
  const [recoverEmail, setRecoverEmail] = useState('');

  const handleClose = () => {
    setError(false);
  }

  const onChangeEmail = (e:React.ChangeEvent<HTMLInputElement>)=>{
    setRecoverEmail( e.target.value );
  }

  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>)=>{
    let user = data;
    user[e.target.name] = e.target.value;
    setData(user);
  }

  const onSubmitHandler = (e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    showLoading(true);
    console.log("LOGIN");
    request('/api/auth/signin',{
      method:'POST',
      body: JSON.stringify( data )
    })
    .then((results:Result)=>{
      if(results.error){
        setMessage({
          title:'Error',
          body: 'El usuario o contraseña no coinciden.'
        });        
        setError(true);
      }else{
        localStorage.user = JSON.stringify(results.data);
        localStorage.token = results.data.token;
        window.location.href = "/home";
      }
      showLoading(false);
    })
  }

  const recoverPassword = () => {
    showLoading(true);
    setPasswordModal(false);
    request('/api/auth/retrievePassword',{
      method:'POST',
      body: JSON.stringify({email: recoverEmail})
    })
    .then((result:Result)=>{
      if(result.error){
        setMessage({
          title:'Error',
          body: 'No se pudo realizar el proceso. Favor de intentarlo más tarde.'
        });        
        setError(true);
      }else{
        setMessage({
          title:'Recuperar contraseña',
          body: 'Se envió un correo a su cuenta para continuar con el proceso.'
        });        
        setError(true);
      }
      showLoading(false);
    });
  }

  return (
    <div className="login">
      {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
      <Row className="justify-content-center w-100">
        <Col xs={12} sm={12} md={8} lg={6}>          
          <Card className="login-form">
            <Form onSubmit={onSubmitHandler}>          
              <Card.Body>
                <Card.Title>Inicio de sesión</Card.Title>
                <Form.Group controlId="email">
                  <Form.Label>Correo</Form.Label>
                  <Form.Control type="email" required placeholder="Ingrese su correo electrónico" name="email" onChange={onChangeHandler} />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" required placeholder="Ingresar contraseña" name="password" onChange={onChangeHandler} />
                </Form.Group>
                <Button variant="link" className="btn-link mr-5" onClick={()=>setPasswordModal(true)}>Recuperar contraseña</Button>
                <Button variant="primary" type="submit">
                  Ingresar
                </Button>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ message.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPasswordModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="email">
            <Form.Label>Ingrese su correo electrónico para enviarle un correo para recuperar su cuenta.</Form.Label>
            <Form.Control type="email" placeholder="Ingrese su correo electrónico" name="email" onChange={onChangeEmail} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="secondary" onClick={recoverPassword}>
            Recuperar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Login;
