export const request = (url:string, options:any) => {
  function handleErrors(response:any) {
      if(response.status === 403 && !options.noRedirect){
        delete localStorage.user;
        delete localStorage.token;
        window.location.href = "/login";
      }else if (!response.ok) {
          return response.text().then((text:any) => {
            response.message = text
            throw response;
          });
      }
      return response;
  }
  if(options['headers']){
    options['headers']['Authorization'] = "Bearer "+localStorage.token;
  }else{
    options['headers'] = {
      'Authorization' : "Bearer "+localStorage.token
    }
  }

  if(options['method'] && !options['file']){
    options['headers']["Content-Type"] = "application/json";
  }

  return fetch(url, options)
  .then( handleErrors)
  .then( (response) => { return response.json() })
  .then(
    (result) => {
      return { data: result, error:null };
    }
  )
  .catch(
    (error) => {
      console.log(error);
      if((error.status && error.status != 403) && url != '/api/auth/signin' && url != '/api/auth/retrievePassword'){
        sendError(error, options, url);
      }
      return { error: error, data:null };
    }
  )
}

export const refreshToken = () => {

  function handleErrors(response:any) {
      if(!response.ok) {
        //console.log(response);
        return response.text().then((text:any) => {
          response.message = text
          throw response;
        })
      }
      return response;
  }
  
  let options:any = { headers:{}, method:'GET'};
  options['headers'] = {
    'Authorization' : "Bearer "+localStorage.token
  }

  return fetch('/api/auth/me', options)
  .then( handleErrors)
  .then( (response) => { return response.json() })
  .then(
    (result) => {
      //console.log(localStorage.token);
      //console.log(result);
      localStorage.token = result.token;
      return result;
      //console.log(localStorage.token);
    }
  )
  .catch(
    (error) => {
      console.log(error, error.error);
    }
  )
}

export const sendError = (error:any, options:any, url:string) => {
  function handleErrors(response:any) {
      if(response.status === 403 && !options.noRedirect){
        delete localStorage.user;
        delete localStorage.token;
        window.location.href = "/login";
      }else if (!response.ok) {
        return response.text().then((text:any) => {
          response.message = text
          throw response;
        });
      }
      return response;
  }

  //console.log(error);


  return fetch("/api/auth/error", {
    method:'POST',
    headers: { 
      "Content-Type": "application/json",  
      'Authorization' : "Bearer "+localStorage.token
    },
    body: JSON.stringify({
      error: {
        status: error.status,
        error: error.message
      },
      routes:{
        current: window.location.href
      },
      request: {
        url: url,
        body: options.body,
        method: options.method
      }
    })
  })
  .then( handleErrors )
  .then( (response) => { return response.json() })
  .then(
    (result) => {
      return { data: result };
    }
  )
  .catch(
    (error) => {
      console.log(error);
      return { error: error };
    }
  )
}