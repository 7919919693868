import React, { useEffect, useState } from "react";
import "../styles/footer.scss";

function Footer() {

  return (
    <div className="footer">
      
    </div>
  );
}

export default Footer;
