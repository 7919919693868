import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const NoSessionRoute = ({flag,...props}:any) => {
	//console.log(flag);
	if(!localStorage.token){
		return <Route {...props} />;
	}else{
		return <Redirect to="/home"/>;			
	}
};

export default NoSessionRoute;