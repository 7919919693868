import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import './App.scss';
import NoSessionRoute from './services/NoSessionRoute';
import ProtectedRoute from './services/ProtectedRoute';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Login from './pages/auth/Login';
import SetPassword from './pages/auth/SetPassword';
import RepositoryList from './pages/repositories/RepositoryList';
import RepositoryNew from './pages/repositories/RepositoryNew';
import FilesList from './pages/files/FilesList';
import FilesNew from './pages/files/FilesNew';
import UserList from './pages/users/UserList';
import UserNew from './pages/users/UserNew';
import UserEdit from './pages/users/UserEdit';

function App() {
  return (
    <Router>
      <div className="App container-fluid p-0">
        <Header />
        <Switch>
          <Route exact path="/">
            <Redirect to="/login"/>
          </Route>
          <NoSessionRoute path="/login" component={Login}/>
          <NoSessionRoute path="/nuevacontrasena/:token" component={SetPassword}/>
          <ProtectedRoute path="/home" component={RepositoryList}/>
          <ProtectedRoute exact path="/repository/new" component={RepositoryNew}/>
          <ProtectedRoute exact path="/repository/files/:id" component={FilesList}/>
          <ProtectedRoute path="/repository/files/:id/new" component={FilesNew}/>
          <ProtectedRoute exact path="/users" setRole={1} component={UserList}/>
          <ProtectedRoute path="/users/new" setRole={1} component={UserNew}/>
          <ProtectedRoute path="/users/edit/:id" setRole={1} component={UserEdit}/>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
