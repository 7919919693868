import React, { useEffect, useState } from "react";
import "../../styles/file.scss";
import { useParams } from 'react-router-dom';
import { Button, Row, Col, Table, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import { Trash, List, Download } from 'react-bootstrap-icons';
import Loader from 'react-loader-spinner';

interface File {
  name:string;
  url:string;
  created_at:string;
  _id:string;
  [key:string]:any;
}

interface Repository {
  name:string;
  _id:string;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

interface routeParams{
  id:string;
}

function FilesList() {

  const [data, setData] = useState<Partial<File>>([]);
  const [showRemove, setRemoveModal] = useState(false);
  const [selectedFile, setSelected] = useState<Partial<File>>({});
  const [repository, setRepository] = useState<Partial<Repository>>({});
  const { id } = useParams<routeParams>();
  const [loading, showLoading] = useState(false);

  useEffect(()=>{
    getFiles();
    getRepository();
  },[]);

  const getFiles= () => {
    showLoading(true);
    request('/api/file/find',{
      method:'POST',
      body:JSON.stringify({repository:id})
    })
    .then((result:Result)=>{
      if(result.error){

      }else{
        setData(result.data);
      }
      showLoading(false);
    });
  }

  const getRepository= () => {
    request('/api/repository/'+id,{})
    .then((result:Result)=>{
      if(result.error){

      }else{
        setRepository(result.data);
      }
    });
  }

  const remove = (item:File) => {
    setRemoveModal(true);
    setSelected(item);
  }

  const handleClose = () => {
    setRemoveModal(false);
  }

  const confirmRemove = () => {
    showLoading(true);
    request('/api/file/'+selectedFile._id,{
      method: 'DELETE'
    })
    .then((result:Result)=>{
      if(result.error){

      }else{
        setRemoveModal(false);
        window.location.reload();
      }
      showLoading(false);
    });
  }

  return (
    <div className="files-list mt-5 pt-5 pb-5">
    {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
    <Row className="">
        <Col xs={12} md={{span:3,offset:2}}>
          <h2 className="text-left">Listado de archivos de {repository.name}</h2>
        </Col>
        <Col xs={12} md={{span:2,offset:3}}>
          <a href={"/repository/files/"+id+"/new"} className="btn btn-primary w-100">
            Nuevo
          </a>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={12} md={{span:8, offset:2}}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Archivo</th>
                <th>Fecha de creación</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {
                data.map((file:File,index:number)=>(
                  <tr>
                    <td>                       
                      { file.name }
                    </td>
                    <td>                       
                      { file.created_at }
                    </td>
                    <td>
                      <a href={file.url} className="btn btn-secondary" target="_blank">
                        <Download/>
                      </a>
                      <Button variant="danger" onClick={() => remove(file) }>
                        <Trash/>
                      </Button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal show={showRemove} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar archivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de eliminar el archivo {selectedFile.name}?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={confirmRemove}>
            Sí
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FilesList;
