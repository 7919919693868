import React, { useEffect, useState } from "react";
import "../../styles/auth.scss";
import { Card, Form, Button, Row, Col, Modal } from "react-bootstrap";
import { request } from "../../services/Request";
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';

interface User {
  email:string;
  password:string;
  password2:string;
  [key:string]:any;
}

interface Result {
  error:any;
  data:any;
}

interface routeParams{
  token:string;
}

function SetPassword() {

  const [data, setData] = useState<Partial<User>>({});
  const { token } = useParams<routeParams>();
  const [loading, showLoading] = useState(false);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState({
    title:'',
    body:''
  })

  const handleClose = () => {
    setError(false);
  }

  const handleSuccessClose = () => {
    window.location.href = "/";
  }

  useEffect(()=>{
    request('/api/auth/getUser',{
      method:'POST',
      body: JSON.stringify({token: token}),
      noRedirect:true
    })
    .then((result:Result)=>{
      if(result.error){
        setMessage({
          title:'Error',
          body: 'No se pudo obtener la información de este token. Se redirigirá a la página inicial.'
        });
        setError(true);
        setTimeout(()=>{
          window.location.href = "/";
        }, 3000);
      }else{
      }
    });

  },[]);

  const onChangeHandler = (e:React.ChangeEvent<HTMLInputElement>)=>{
    let user = data;
    user[e.target.name] = e.target.value;
    setData(user);
  }

  const onSubmitHandler = (e:React.FormEvent<HTMLFormElement>)=>{
    e.preventDefault();
    if(data.password != data.password2){
      setMessage({
        title:'Error',
        body: 'Las contraseñas no coinciden.'
      });
      setError(true);
    }else{      
      request('/api/auth/setpassword',{
        method:'PUT',
        body: JSON.stringify({
          password: data.password,
          token: token
        })
      })
      .then((results:Result)=>{
        if(results.error){
          setMessage({
            title:'Error',
            body: 'Ocurrió un error al establecer la nueva contraseña.'
          });
          setError(true);
        }else{
          setSuccess(true);
        }
      })
    }
  }

  return (
    <div className="login">
    {
        loading &&
        <div className="loader">
          <Loader
             type="Oval"
             color="#e6520f"
             height={300}
             width={300}
          />
        </div>
      }
      <Row className="justify-content-center w-100">
        <Col xs={12} sm={12} md={8} lg={6}>          
          <Card className="login-form">
            <Form onSubmit={onSubmitHandler}>          
              <Card.Body>
                <Card.Title>Establecer contraseña</Card.Title>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" placeholder="Ingresar contraseña" name="password" onChange={onChangeHandler} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control type="password" placeholder="Ingresar nuevamente la contraseña" name="password2" onChange={onChangeHandler} />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Ingresar
                </Button>
              </Card.Body>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal show={showError} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{ message.title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message.body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSuccess} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contraseña establecida.</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ya puedes ingresar la plataforma con tu correo y contraseña ingresados.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SetPassword;
